import React from 'react';
import loadable from '@loadable/component'

const ChangePasswordComponent = loadable(() => import('../../components/Account/ResetPassword'))

const ResetPassword = () => {
  return (
    <>
      <ChangePasswordComponent/>
    </>
  );
};

export default ResetPassword;