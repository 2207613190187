import React from 'react';
import {toast} from "react-toastify";
import loadable from '@loadable/component'
import {axiosInstance, noAuthInstance} from "../../helpers/axiosInstance";
import APIS from "../../constants/EndPoint";
import {setLocalKey} from "../../helpers/sessionKey";
import {useNavigate} from "react-router-dom";

const LoginComponent = loadable(() => import('../../components/Account/Login'))

const Login = () => {
  const navigate = useNavigate()
  const handleLogin = async (data: any, reset: any) => {
    await noAuthInstance.post(APIS.CREATE_TOKEN, data)
      .then(function (response) {
        if (response?.status === 200) {
          setLocalKey("token", JSON.stringify(response.data))
          navigate("/")
          toast.success("Login successfully")
        } else {
          toast.error("Something went wrong")
        }
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.detail ?? "Something went wrong")
        reset()
      });
  }


  return (
    <>
      <LoginComponent handleLogin={handleLogin}/>
    </>
  );
};

export default Login;