const APIS = {
  CREATE_TOKEN: "/token/",
  SERVICES: "/services/",
  CLIENTS: "/client/",
  ATTENDANCE_DEVICE: "/attendance-device/",
  DOMAIN_NAME: "/domain/",
  EMAIL_SERVER: "/email-server/",
  SMS_PROVIDER: "/sms-provider/",
  CLIENT_SERVICES: "/assign-services/",
  REORDER_SERVICE: "/client/reorder-service/",
  LICENSE: "/assign-services/",
  TASKINTERVAL: "/task-interval/",
  PERIODICTASK: "/periodic-task/",
  SYNCTASKHISTORY: "/task-result/",
};

export default APIS;
