import React, { useState } from 'react'
import loadable from '@loadable/component';
import usePersistentState from "../../customHooks/usePersistentStateHook";
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../constants/EndPoint';
import useDeleteHook from '../../customHooks/useDeleteHook';
const SyncComponent = loadable(() => import('../../components/Sync/SyncInterval'))
const SyncInterval = () => {
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const {
    isPostLoading,
    mutateAsync: createClientMutate,
    isPostSuccess
  } = usePostHook({
    queryKey: "task-interval",
    navigateURL: null
  });
  const handleCreateInterval = async (value: any, reset: any, setError: any) => {
    const url = APIS.TASKINTERVAL
    const formData = {
      every: value?.number,
      period: value?.interval,
    }
    try {
      await createClientMutate({ url, formData, reset, setError })
      //@ts-ignore
      setAddPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  const {
    isLoading,
    mutateAsync: deleteClientMutate,
  } = useDeleteHook({
    queryKey: "task-interval"
  });
  const handleDeleteClick = async () => {
    const url = `${APIS.TASKINTERVAL}${deleteId}/`
    try {
      await deleteClientMutate({ url })
      setDeletePopup(false)
    }
    catch (err) {
      console.log(err);
    }
  }
  return (
    <SyncComponent
      setAddPopup={setAddPopup}
      addPopup={addPopup}
      deletePopup={deletePopup}
      handleCreateInterval={handleCreateInterval}
      setDeletePopup={setDeletePopup}
      handleDeleteClick={handleDeleteClick}
      setDeleteId={setDeleteId}
    />
  )
}

export default SyncInterval