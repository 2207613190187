import loadable from '@loadable/component';
import React from 'react'
import { useParams } from 'react-router-dom';
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../constants/EndPoint';
import usePostHook from '../../customHooks/usePostHook';
const ClientServiceSubscriptionComponent = loadable(() => import('../../components/Client/ClientServiceSubscription'))
const ClientServiceSubscription = () => {
  const { id } = useParams();


  const { data: clientservices } = useGetHook({
    queryKey: ["clientservices-${id}"],
    url: `${APIS.CLIENT_SERVICES}${id}`,
    params: {
    }
  });
  console.log(clientservices, "data")
  const { mutateAsync: createClientServices, } = usePostHook({
    queryKey: [`clientservices-${id}`],
    navigateURL: null
  });
  const handleClientServiceCreate = async (data: any, setAddService: any) => {
    const url = `${APIS.CLIENTS}${clientservices?.client}/renew-service/`
    const formData = {
      service_id: clientservices?.id,
      subscription_type: data?.subscription,
      type: data?.type,
      durations: data?.duration
    }
    try {
      const res = await createClientServices({ url, formData })
      //@ts-ignore
      setAddService(false)
    } catch (err) {
      console.log(err);
    }
    console.log(data)
  }

  return (
    <ClientServiceSubscriptionComponent
      clientservices={clientservices}
      handleClientServiceCreate={handleClientServiceCreate}
    />
  )
}

export default ClientServiceSubscription