import React, { Fragment } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import DibTechLogo from "../assets/icons/dibtechLogo.svg"
import UserIcon from "../assets/icons/userIcon.svg"
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../utils/classNames';
import ServiceIcon from "../assets/icons/services.svg"
import ClientIcon from "../assets/icons/clients.svg"
import { removeLocalKey } from "../helpers/sessionKey";
import { toast } from "react-toastify";

const navigation: any = [
  { name: 'Service', href: '/', icon: ServiceIcon },
  { name: 'Clients', href: '/clients', icon: ClientIcon },
  { name: 'License', href: '/license', icon: ClientIcon },
  { name: 'Sync Interval', href: '/syncInterval', icon: ClientIcon },
  { name: 'Sync Task History', href: '/syncTaskHistory', icon: ClientIcon },
]

const SideBar = (props: any) => {
  const { open, setOpen, toggleSidebar } = props;
  const navigate = useNavigate()

  const handleLogout = () => {
    removeLocalKey("token")
    navigate("/login")
    toast.success("Logout successfully")
  }

  return (
    <div>
      <div className="flex items-center flex-shrink-0 px-4">
        <img
          className="h-8 w-auto"
          src={DibTechLogo}
          alt="DibTech Logo"
        /><span>ibtech Clients</span>
      </div>
      {/* Profile dropdown */}
      <Menu as="div" className="ml-3 relative shadow  m-[20px] p-[10px] rounded-lg">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className="max-w-xs flex items-center text-sm rounded-full focus:outline-none"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-12 w-12 rounded-full"
                  src={UserIcon}
                  alt="User Icons"
                />
                <span className={"m-4 text-left"}>Sandeep Kushwaha <br /><span
                  className={"text-left text-gray-400"}>Super User</span></span>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to={"/profile"}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {"Profile"}
                    </NavLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  <p onClick={handleLogout}
                    className={"block px-4 py-2 text-sm cursor-pointer text-gray-700"}>Sign Out</p>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <div className="mt-5 flex-1 flex flex-col">
        <nav className="flex-1 px-2 space-y-1">
          {navigation.map((item: any) => (
            <NavLink
              key={item.name}
              to={item.href}
              onClick={toggleSidebar}
              className={({ isActive, isPending }) => {
                return `${isActive ? "bg-[#5B6AB0]  text-white" : isPending ? "text-indigo-100 hover:bg-indigo-600" : ""} group flex items-center px-2 py-2 text-sm font-medium rounded-md`;
              }}
            >
              <img src={item.icon} className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
              {item.name}
            </NavLink>
          ))}
          <a
            target='_blank'
            href={"https://docs.dibsolutions.com.au/docs/root/html/index.html"}
            className={"group flex items-center px-2 py-2 text-sm font-medium rounded-md"}
          >
            <img src={ClientIcon} className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
            Docs
          </a>
        </nav>
      </div>
    </div>
  )
}

export default SideBar