import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import usePersistentState from "../customHooks/usePersistentStateHook";
import { Outlet } from 'react-router-dom';
import BreadCrumb from "../components/Reusable/BreadCrumb";
import AddIcon from "../assets/icons/addIcon.svg"
import SideBar from './SideBar';

export default function DetailDashboardLayout() {
  const [open, setOpen] = useState(false);
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup")

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Sidebar for large devices */}
      <div className="hidden sm:block w-64">
        <div className="fixed inset-y-0 left-0 max-w-full flex">
          {/* Your sidebar content */}
          <div className="bg-[#F4F5FA] md:flex md:flex-shrink-0 p-2 shadow rounded-r-2xl">
            <div className="flex flex-col w-64">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
                <SideBar open={open} setOpen={setOpen} toggleSidebar={null} />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Menu icon for medium and small devices */}
      <div className="sm:hidden">
        {
          !open ? (
            <button
              type="button"
              className="fixed top-5 left-4 z-50 bg-white p-2 rounded-md shadow-md"
              onClick={toggleSidebar}
            >
              <Bars3Icon className="h-6 w-6 text-gray-500" aria-hidden="true" />
            </button>
          ) : ("")
        }
      </div>

      {/* Sidebar dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden sm:hidden"
          onClose={toggleSidebar}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition duration-300 ease-in-out sm:duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition duration-300 ease-in-out sm:duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="fixed inset-y-0 left-0 max-w-full flex">
              {/* Your sidebar content */}
              <div className="bg-[#F4F5FA] md:flex md:flex-shrink-0 p-2 shadow rounded-r-2xl">
                <div className="flex flex-col w-64">
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex flex-col flex-grow pb-4 overflow-y-auto">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700 focus:outline-none flex justify-end"
                      onClick={toggleSidebar}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <SideBar open={open} setOpen={setOpen} toggleSidebar={toggleSidebar} />
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative overflow-y-auto  focus:outline-none">
          <div className="py-6">
            <div className="max-w-10xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="flex justify-between items-center">
                <div className='ml-12 sm:ml-2'>
                  <BreadCrumb />
                </div>
              </div>
              <div className="my-5 md:my-4 overflow-y-auto">
                <Outlet />
                {/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"/> */}
              </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}