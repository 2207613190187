import {NavLink, useParams} from "react-router-dom";

const BreadCrumb = () => {
  const params = useParams()
  const getPagesLink = () => {
    let pages: any = [];
    let links = window.location.pathname.split("/");
    if (links.length === 2) {
      links.map(
        (item: string, index: number) =>
          index !== 0 &&
          !Object.values(params).includes(item) &&
          pages.push({
            name: item?.toUpperCase(),
            href: `/${item}`,
            current: links.length - 1 === index,
          }),
      );
    } else {
      links.map(
        (item: string, index: number) =>
          index !== 0 &&
          index !== links.length - 1 &&
          !Object.values(params).includes(item) &&
          pages.push({
            name: item?.toUpperCase(),
            href: links.length - 2 === index ? "#" : `/${item}`,
            current: links.length - 2 === index,
          }),
      );
    }
    return pages;
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {getPagesLink().map((page: any) => (
          <li key={page.name}>
            <div className="flex items-center">
              <NavLink
                to={page.href}
                className="ml-2 font-bold text-2xl text-primary"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name.length ? page.name : "SERVICE"}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
export default BreadCrumb