import React from 'react'
import loadable from '@loadable/component';
const SyncTaskHistoryComponent = loadable(() => import('../../components/SyncTaskHistory'))
const SyncTaskHistory = () => {
  return (
    <>
      <SyncTaskHistoryComponent />
    </>
  )
}

export default SyncTaskHistory