import Axios from "axios";
import {getAccessToken} from "./sessionKey";
import { toast } from "react-toastify";

const API_ROOT = process.env.REACT_APP_SERVER_ENDPOINT


const axiosInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-type": "Application/json",
    // 'Content-Type': 'multipart/form-data'
    "Authorization": `JWT ${getAccessToken()}`,

  },
});

export const noAuthInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-type": "Application/json",
  },
});
axiosInstance.interceptors.request.use(
  function(config:any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${getAccessToken()}`,
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);

 // create axios instance for form data to upload file
 export const formDataInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": `multipart/form-data`,
  },
});

formDataInstance.interceptors.request.use(
  function(config:any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${getAccessToken()}`,
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

formDataInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);

//get data from api
export const getApiData = async (url:any, params = null) => {
  let response;
  try {
    response = await axiosInstance({
      method: "GET",
      url: `${url}`,
      params: params,
      headers: {
        Authorization: `JWT ${getAccessToken()}`,
      },
      transformResponse: [function (responseData) {
        // Do whatever you want to transform the data
        return JSON.parse(responseData);
      }],
    });
  } catch (e:any) {
    return e.response;
  }
  return response;
}

export const postApiData = async (data:any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await axiosInstance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${getAccessToken()}`,
      },
      transformResponse: [
        function (responseData) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e:any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key)) {
        setError(key, { type: 'custom', message: e.response?.data[key] });
      }
    }
    return e.response;
  }
  return response;
};

//delete data
export const deleteApiData = async (props:any) => {
  let response;
  response = await axiosInstance({
    method: "DELETE",
    url: props.url, 
    // params: param,
    headers: {
      Authorization: `JWT ${getAccessToken()}`,
    },
  });
  return response;
};

//update data
export const patchApiData = async (data:any) => {
  const { url, formData } = data;
  let response;
  try {
    response = await axiosInstance({
      method: "PATCH",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${getAccessToken()}`,
      },
      transformResponse: [
        function (responseData: any) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e:any) {
    // formikProps.setErrors(e.response.data);
    return e.response;
  }
  return response;
};

// create form data to upload files
export const postApiFormData = async (fileData: any) => {
  const { url, formData } = fileData;
  let response;
  try {
    response = await formDataInstance({
      method: "POST",
      url: `${url}`,
      headers: {
        Authorization: `JWT ${getAccessToken()}`,
      },
      data: formData,
      transformResponse: [
        function (responseData) {
          //Do what ever you want transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e:any) {
    // eslint-disable-next-line no-console
    console.log(e.response);
    return e.response;
  }
  return response;
};

export {axiosInstance, API_ROOT};