import React from 'react'
import loadable from '@loadable/component'
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../constants/EndPoint';

const LicenseServicesComponent = loadable(() => import('../../components/License/LicenseService'))
const LicenseService = () => {
  const { data: servicesData, isLoading } = useGetHook({
    queryKey: ["services-license"],
    url: `${APIS.SERVICES}`,
    params: {
      "limit": 100,
      "offset": 0
    }
  });
  console.log(servicesData?.results || [])
  return (
    <LicenseServicesComponent
    data={servicesData?.results || []}
     />
  )
}

export default LicenseService