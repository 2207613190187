import loadable from '@loadable/component';
import { useParams } from 'react-router-dom';
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../constants/EndPoint';
import usePersistentState from "../../customHooks/usePersistentStateHook";
import usePostHook from '../../customHooks/usePostHook';
import { async } from 'q';
import useDeleteHook from '../../customHooks/useDeleteHook';

const ClientDetailComponent = loadable(() => import('../../components/Client/ViewClient'))

const ClientDetail = () => {
  const { id } = useParams()
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup")
  const [addServicesPopup, setAddServicesPopup] = usePersistentState("openAddServicesPopup")
  const [addDomainPopup, setAddDomainPopup] = usePersistentState("openAddDomainPopup")

  const { data: client, isLoading } = useGetHook({
    queryKey: [`client-detail-${id}`],
    url: `${APIS.CLIENTS}${id}/`,
    params: {}
  });

  const { isPostLoading: isClientServiceLoading, mutateAsync: mutateClientServices, isPostSuccess: isClientServiceSucesss } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });
  const handleUpdateClientService = async (values: any, setAddService: any) => {
    const formData = {
      services: values?.services
    }
    const url = `${APIS.CLIENTS}${id}/assign-service/`
    try {
      await mutateClientServices({ url, formData })
      setAddService(false)
    } catch (err) {
      console.log(err);
    }
    console.log(values)
  }

  const { data: servicesData, } = useGetHook({
    queryKey: ["services"],
    url: `${APIS.SERVICES}`,
    params: {
      "limit": 100,
      "offset": 0
    }
  });

  const {
    isPostLoading,
    mutateAsync: createAttendanceDevice,
  } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });

  const { mutateAsync: createDomainName, } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });

  const { mutateAsync: createServices, } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });

  const { mutateAsync: createEmailServerForm, } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });

  const { mutateAsync: createSmsProvider, } = usePostHook({
    queryKey: [`client-detail-${id}`],
    navigateURL: null
  });


  const handleAttendanceDeviceCreate = async (data: any) => {
    const url = APIS.ATTENDANCE_DEVICE
    const formData = {
      tenant: id,
      provider: "digicom",
      device_name: data?.device_name,
      is_active: true,
      device_id: data?.device_id,
      other_data: {}
    }
    try {
      const res = await createAttendanceDevice({ url, formData })
      //@ts-ignore
      setAddPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  const handleDomainCreate = async (data: any) => {
    const url = APIS.DOMAIN_NAME
    const formData = {
      client: id,
      domain: data?.domain_name,
      type: data?.domain_type
    }
    try {
      await createDomainName({ url, formData })
      //@ts-ignore
      setAddDomainPopup(false)

    } catch (err) {
      console.log(err);
    }
  }

  const handleServicesCreate = async (data: any) => {
    console.log(data, "data");

    const url = APIS.SERVICES
    const formData = {
      client: id,
      name: data?.service_name,
      // status: ,
      // logo: ,
      // path: ,
    }
    // try {
    //   await createServices({ url, formData })
    //   //@ts-ignore
    //   setAddServicesPopup(false)

    // } catch (err) {
    //   console.log(err);
    // }
  }

  const handleEmailServerForm = async (data: any) => {
    const url = APIS.EMAIL_SERVER
    const formData = {
      tenant: id,
      host: data?.host,
      port: data?.port,
      from_email: data?.from_email,
      username: data?.user_name,
      password: data?.password,
      use_tls: false,
      use_ssl: false,
      fail_silently: false,
      timeout: data?.time_out,
    }
    try {
      await createEmailServerForm({ url, formData })
    } catch (error) {
      console.log(error)
    }

  }

  const handleSmsProvider = async (data: any) => {
    const url = APIS.SMS_PROVIDER
    const formData = {
      tenant: id,
      provider: data?.provider,
      private_key: data?.private_key,
      public_key: data?.public_key,
    }
    try {
      await createSmsProvider({ url, formData })
    } catch (error) {
      console.log(error)
    }
  }

  const { mutateAsync: deleteAttendanceDeviceMutateAsync } = useDeleteHook({ queryKey: ["clients", `client-detail-${id}`] })

  const handleAttendanceDeviceDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.ATTENDANCE_DEVICE}${pk}/`
    try {
      const res = await deleteAttendanceDeviceMutateAsync({ url })
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  const { mutateAsync: deleteDomainCreateMutateAsync } = useDeleteHook({ queryKey: ["clients", `client-detail-${id}`] })

  const handleDomainCreateDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.DOMAIN_NAME}${pk}/`
    try {
      const res = await deleteDomainCreateMutateAsync({ url })
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  const { mutateAsync: deleteServicesMutateAsync } = useDeleteHook({ queryKey: ["clients", `client-detail-${id}`] })

  const handleServicesDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.DOMAIN_NAME}${pk}/`
    try {
      const res = await deleteServicesMutateAsync({ url })
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  console.log(client, "client")
 

  return (
    <ClientDetailComponent
      client={client}
      Servicesdata={servicesData?.results || []}
      handleDomainCreate={handleDomainCreate}
      handleEmailServerForm={handleEmailServerForm}
      handleAttendanceDeviceCreate={handleAttendanceDeviceCreate}
      handleSmsProvider={handleSmsProvider}
      handleServicesCreate={handleServicesCreate}
      addPopup={addPopup}
      setAddPopup={setAddPopup}
      addDomainPopup={addDomainPopup}
      setAddDomainPopup={setAddDomainPopup}
      addServicesPopup={addServicesPopup}
      setAddServicesPopup={setAddServicesPopup}
      handleAttendanceDeviceDelete={handleAttendanceDeviceDelete}
      handleDomainCreateDelete={handleDomainCreateDelete}
      handleServicesDelete={handleServicesDelete}
      handleUpdateClientService={handleUpdateClientService}
    />
  );
};

export default ClientDetail;
