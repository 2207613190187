import loadable from '@loadable/component'
import APIS from "../../constants/EndPoint";
import usePostHook from "../../customHooks/usePostHook";
import usePersistentState from "../../customHooks/usePersistentStateHook";

const ClientsComponent = loadable(() => import('../../components/Client/Clients'))

const Clients = () => {
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup")

  const {
    isPostLoading,
    mutateAsync: createClientMutate,
  } = usePostHook({
    queryKey: "clients",
    navigateURL: null
  });

  const handleCreateClient = async (value: any, reset: any, setError: any) => {
    const url = APIS.CLIENTS
    const formData = {
      name: value?.client_name,
      domain: value?.domain,
      business_name: value?.business_name,
      email: value?.email,
      add_services: []
    }
    await createClientMutate({url, formData, reset, setError})
  }


  return (
    <>
      <ClientsComponent
        setAddPopup={setAddPopup}
        addPopup={addPopup}
        isPostLoading={isPostLoading}
        handleCreateClient={handleCreateClient}
      />
    </>
  );
};

export default Clients;