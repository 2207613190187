import React from "react";
import { createBrowserRouter, } from "react-router-dom";
import Login from "../pages/Account/Login"
import DashboardLayout from "../layouts/DashboardLayout";
import PrivateRoute from "./PrivateRoute";
import AuthLayout from "../layouts/AuthLayout";
import Services from "../pages/Service/Services";
import Clients from "../pages/Client/Clients";
import ForgetPassword from "../pages/Account/ForgetPassword";
import ResetPassword from "../pages/Account/ResetPassword";
import Signup from "../pages/Account/Signup";
import DetailDashboardLayout from "../layouts/DetailDashboardLayout";
import ViewClient from "../pages/Client/ClientDetail";
import ClientDetail from "../pages/Client/ClientDetail";
import ClientServiceSubscription from "../pages/Client/ClientServiceSubscription";
import LicenseService from "../pages/License/LicenseService";
import LicenseList from "../pages/License/LicenseList";
import SideBar from "../layouts/SideBar";
import SyncInterval from "../pages/SyncInterval/SyncInterval";
import EditSync from "../pages/SyncInterval/EditSync";
import SyncTaskHistory from "../pages/SyncTaskHistory";


const router = createBrowserRouter([
  {
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),

    children: [
      {
        path: "/",
        element: <Services />,
      },

      {
        path: "/clients",
        element: <Clients />,
      },
      {
        path: "/view-client",
        element: <ViewClient />,
      },
      {
        path: "/license",
        element: <LicenseService />,
      },
      {
        path: "/syncTaskHistory",
        element: <SyncTaskHistory />,
      },
      {
        path: "/edit-syncInterval/:id",
        element: <EditSync />,
      },
      {
        path: "/syncInterval",
        element: <SyncInterval />,
      },
      {
        path: "/license-list/:id",
        element: <LicenseList />,
      },


    ],

  },
  {
    element: (
      <PrivateRoute>
        <DetailDashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/client/:id",
        element: <ClientDetail />,
      },
      {
        path: "/client-service-subscription/:id",
        element: <ClientServiceSubscription />,
      },


    ],
  },
  {
    element: (
      <AuthLayout />
    ),
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
]);

export default router