import React from 'react';
import loadable from '@loadable/component'

const ForgetPasswordComponent = loadable(() => import('../../components/Account/ForgetPassword'))

const ForgetPassword = () => {
  return (
    <>
      <ForgetPasswordComponent/>
    </>
  );
};

export default ForgetPassword;