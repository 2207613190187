import loadable from '@loadable/component'
import { useState } from 'react';
import APIS from '../../constants/EndPoint';
import useGetHook from "../../customHooks/useGetHook";
import usePostFormData from '../../customHooks/usePostFormData';
import usePatchHook from '../../customHooks/usePatchHook';
import usePostHook from '../../customHooks/usePostHook';
import usePersistentState from "../../customHooks/usePersistentStateHook";

const ServicesComponent = loadable(() => import('../../components/Service/Services'))

const Services = () => {
  const [editPopup, setEditPopup] = useState<boolean>(false)
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup")

  const { data: servicesData, isLoading, } = useGetHook({
    queryKey: ["services"],
    url: `${APIS.SERVICES}`,
    params: {
      "limit": 100,
      "offset": 0
    },
  });

  console.log(servicesData, "servicesData");

  const { mutateAsync: AddServices } = usePostFormData({
    queryKey: ["services"],
    navigateURL: null
  });

  const { mutateAsync } = usePatchHook({
    queryKey: ["services"],
    navigateURL: null
  });
  const {mutateAsync: mutateServicesPrice } = usePostHook({
    queryKey: ["services"],
    navigateURL: null
  });
  const handleUpdateServicePrice = async (values: any, setAddService: any) => {
    const formData = {
      prices: values?.services
    }
    const url = `${APIS.SERVICES}${values?.service_id}/update-price/`
    try {
      await mutateServicesPrice({ url, formData })
      setAddService(false)
    } catch (err) {
      console.log(err);
    }
  }
  const handleAddService = async (values: any) => {
    let formData = new FormData()
    formData.append("name", values.name)
    formData.append("path", values.path)
    const url = APIS.SERVICES
    try {
      await AddServices({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  const handleEditService = async (data: string, id: any) => {
    setEditPopup(false)
    const url = `${APIS.SERVICES}${id}/`
    const formData = {
      status: data
    }
    console.log(formData)
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  const {
    mutateAsync: mutateReorderService,
  } = usePostHook({
    queryKey: ["services"],
    navigateURL: null
  });

  const handleReorderService = async (data: any) => {
    console.log(data, "submitData")
    const url = APIS.REORDER_SERVICE
    // const { data: { current: { sortable: { items } } } } = data;
    const formData = {
      // services: items?.map((item: any, index: number) => ({
      //   service: item,
      //   index: index,
      // })),
      services: data?.map((item:any, index:number) => ({
        service: item?.id,
        index: index,
      }))
    };
    console.log(formData, "formData")
    try {
      const res = await mutateReorderService({ url, formData })
      console.log(res, "res")
      //@ts-ignore
      setAddPopup(false)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      {
        isLoading ? " " :

          <ServicesComponent
            data={servicesData?.results || []}
            setEditPopup={setEditPopup}
            editPopup={editPopup}
            handleAddService={handleAddService}
            handleEditService={handleEditService}
            handleUpdateServicePrice={handleUpdateServicePrice}
            handleReorderService={handleReorderService}
          />
      }
    </>
  );
};

export default Services;


