import {Outlet} from "react-router-dom";

const AuthLayout = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-4 px-12 shadow rounded-2xl">
          <div className={"w-full text-center my-2 text-2xl font-medium"}>
            <h1>LOGIN</h1>
          </div>
          <Outlet/>
          <div className="text-right my-2">
            <a href="#" className="font-medium">
              Forgot your password?
            </a>
          </div>
          <div className="text-center mt-8">
            <a href="#">
              Don't you have an account? <span className={"underline"}>Signup</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout