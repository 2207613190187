import { useQuery } from "@tanstack/react-query";
import { getApiData } from "../helpers/axiosInstance";
interface IUseGetHook{
  queryKey:any
  url:string
  params:any
}

const useGetHook = (props:IUseGetHook) => {
  const {queryKey, url , params} = props
  const { isLoading, isError, data, isSuccess } = useQuery(queryKey, async () => {
    const response = await getApiData(url, params);
    return response.data;
  },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,

    });

  return { isLoading, isError, data, isSuccess };
};

export default useGetHook;