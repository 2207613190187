import React from 'react'
import loadable from '@loadable/component';
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../constants/EndPoint';
import { useParams } from 'react-router-dom';
import usePostHook from '../../customHooks/usePostHook';
import usePatchHook from '../../customHooks/usePatchHook';
const EditSyncComponent = loadable(() => import('../../components/Sync/EditSync'))
const EditSync = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetHook({
    queryKey: [`task-interval:${id}`],
    url: `${APIS.TASKINTERVAL}${id}`,
    params: {
    }
  });
  const {
    isLoading: isPatchLoading,
    mutateAsync: editClientMutate,
  } = usePatchHook({
    queryKey: [`task-interval:${id}`],
    navigateURL: "/syncInterval"
  });

  const handleEditInterval = async (value: any) => {
    const url = `${APIS.TASKINTERVAL}${id}/`
    const formData = {
      every: value?.number,
      period: value?.interval,

    }
    await editClientMutate({ url, formData })
  }
  return (
    <div>{!data && isPatchLoading ? "" :
      <EditSyncComponent data={data} handleEditInterval={handleEditInterval} isLoading={isLoading} />
    }</div>
  )
}

export default EditSync