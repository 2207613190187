import React from 'react'
import loadable from '@loadable/component'
import useGetHook from '../../customHooks/useGetHook'
import APIS from '../../constants/EndPoint'


const LicenseListComponent = loadable(() => import('../../components/License/LicenseList'))

const LicenseList = () => {

  const { data: licenseData, isLoading } = useGetHook({
    queryKey: ["services"],
    url: `${APIS.LICENSE}`,
    params: {
      "limit": 100,
      "offset": 0,
    }
  });
  console.log(licenseData, "licenseData")
  
  return (
    <LicenseListComponent 
    licenseData={licenseData}
    isLoading={isLoading}
    />
  )
}

export default LicenseList